export function getIn<T extends object, K extends Leaves<T>>(
    object: T,
    path: K
): DeepIdx<T, K> {
    const parts = path.split('.');
    return parts.reduce<DeepIdx<T, K>>((result, key) => {
        // @ts-expect-error key index
        if (result && result[key]) {
            // @ts-expect-error key index
            return result[key];
        }

        return undefined;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, object as any);
}
