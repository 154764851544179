import { CommitteeMemberData } from 'api/committies/committies.types';
import { Popup } from 'components/Popup';
import { FormApi } from 'final-form';
import { useSetFalse, useSetTrue } from 'hooks/booleans';
import { getTranslateGroup } from 'messages';
import { FC, useCallback, useState } from 'react';
import { Form } from 'react-final-form';
import { FieldArrayRenderProps } from 'react-final-form-arrays';
import Button from 'ui/Button/Button';
import FormContainer from 'ui/FormContainer/FormContainer';
import FormField from 'ui/FormField/FormField';
import { RadioGroupField } from 'ui/RadioGroup';
import { generateOptions } from 'utils/options';
import * as S from './AddComissionMember.styled';
import { RemoveIcon } from 'assets/Remove';

type AddComissionMemberProps = FieldArrayRenderProps<
    CommitteeMemberData,
    HTMLElement
>;

const PopupField = FormField<CommitteeMemberData>;

const typeOptions = generateOptions('committeeMemberType');

const memberTypes = getTranslateGroup('committeeMemberType');

export const AddComissionMember: FC<AddComissionMemberProps> = ({ fields }) => {
    const [isOpen, setIsOpen] = useState(false);

    const add = useSetTrue(setIsOpen);
    const close = useSetFalse(setIsOpen);

    const onSubmit = useCallback(
        (data: CommitteeMemberData, form: FormApi<CommitteeMemberData>) => {
            fields.push(data);
            close();
            form.restart();
        },
        [fields, close]
    );

    return (
        <>
            {fields.map((name, index) => (
                <FormField
                    key={name}
                    name={`${name}.fullName`}
                    label={
                        memberTypes[fields.value[index].jobTitleMemberCommitee]
                    }
                    rightContent={
                        <S.RemoveButton onClick={() => fields.remove(index)}>
                            <RemoveIcon />
                        </S.RemoveButton>
                    }
                />
            ))}
            <Button onClick={add}>Добавить</Button>
            <Popup isOpen={isOpen} onClose={close}>
                <Form onSubmit={onSubmit}>
                    {({ handleSubmit, submitting, valid }) => (
                        <FormContainer
                            onSubmit={valid ? handleSubmit : undefined}
                        >
                            <PopupField
                                name="fullName"
                                label="Создать члена комиссии"
                                placeholder="Введите имя"
                                required
                            />
                            <RadioGroupField<CommitteeMemberData>
                                name="jobTitleMemberCommitee"
                                options={typeOptions}
                                required
                            />
                            <Button
                                type="submit"
                                disabled={submitting || !valid}
                            >
                                Добавить
                            </Button>
                        </FormContainer>
                    )}
                </Form>
            </Popup>
        </>
    );
};
