import { get, postWithToast } from '../../utils/fetch';
import { CurrentOperator } from '../../api/organizations/operators.types';

export const getCurrentOperators = () =>
    get<CurrentOperator[]>('Organization/Current/GetOperators');

//данные для таблицы с ожидаемыми типами
export const fetchCurrentOperators = async (
    search?: string
): Promise<Record<string, unknown>[]> => {
    const response = await get<{ items: CurrentOperator[] }>(
        'Organization/Current/GetOperators'
    );

    // Извлекаем массив операторов из `items`
    const operators = response?.items || [];

    // Фильтрация, если передан параметр `search`
    const filteredData = search
        ? operators.filter((operator) =>
              operator.fullName.toLowerCase().includes(search.toLowerCase())
          )
        : operators;

    // Преобразуем в нужный формат
    return filteredData.map((operator) => ({
        id: operator.id,
        fullName: operator.fullName,
        email: operator.email
    }));
};

// export const updateOperator = () => postWithToast<>;
