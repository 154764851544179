import styled, { css } from 'styled-components';
import { headlineSizeL } from '../../tokens';
import { themeable } from 'themes/utils';

export const Headline = styled.h1`
    font-size: ${headlineSizeL};
    color: ${themeable('textColor')};
    font-weight: 400;
    margin-bottom: 24px;
    text-align: center;
`;

export const Title = styled.p`
    color: rgb(255, 255, 255);
    //font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
`;
//обычный белый по больше текст
export const Typography1 = styled.p`
    color: rgb(255, 255, 255);
    //font-family: Barlow;
    font-size: 24px;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 0%;
`;
//обычный белый по меньше текст
export const Typography2 = styled.p`
    color: rgb(255, 255, 255);
    //font-family: Inter;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0%;
`;
//обычный серый текст
export const Typography3 = styled.p`
    color: rgb(171, 187, 194);
    //font-family: Barlow;
    font-size: 16px;
    font-weight: 500;
    line-height: 130%;
    letter-spacing: 0%;
`;
//обычный синий цвет
export const Typography4 = styled.p`
    color: rgb(113, 142, 191);
    //font-family: Inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0%;
`;

export const absoluteMixin = css`
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
`;

export const RelativeContainer = styled.div`
    position: relative;
`;
