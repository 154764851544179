import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { getStudents } from '../../api/students/students';
import { StudentInfo } from '../../api/students/students.types';
import { ColumnProps, ParseTableColumns } from '../../ui/Table';
import { PowerTable } from 'ui/PowerTable';
import { Headline } from 'ui/Styled/Styled';
import { RouterPaths } from 'helpers/router-paths';
import { formatDate } from 'utils/date';
import { NoAvatar } from 'ui/Menu/assets/ItemsIcons';
import styled from 'styled-components';

const AvatarContainer = styled.div`
    border: 1px solid #718ebf;
    border-radius: 8px;
    width: 60px;
    height: 60px;
`;

const STUDENTS_COLUMNS: ColumnProps<StudentInfo> = {
    avatarUrl: { title: '', width: 60 },
    fullName: 'ФИО',
    phone: 'Номер телефона',
    birthday: 'Дата рождения',
    email: 'Почта'
};

const parseColumns: ParseTableColumns<StudentInfo> = {
    birthday: formatDate,

    avatarUrl: (url) => (
        <AvatarContainer>
            {url ? <img src={url} /> : <NoAvatar />}
        </AvatarContainer>
    )
};

const StudentsList = () => {
    return (
        <>
            <Headline>Студенты</Headline>
            <PowerTable
                columns={STUDENTS_COLUMNS}
                getter={getStudents}
                linkGetter={RouterPaths.StudentProfile}
                parseColumns={parseColumns}
                noRowsMsg="Студенты не найдены"
            />
        </>
    );
};

export default withAuth(withContainer(StudentsList));
