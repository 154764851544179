import React, { useState, useCallback } from 'react';
import { LoadFileIcon } from 'ui/Menu/assets/ItemsIcons';

interface FileInputButtonProps {
    onFileChange: (file: File | null) => void; // Колбек для обработки выбранного файла
}

export const RawFileButton: React.FC<FileInputButtonProps> = ({
    onFileChange
}) => {
    const [fileName, setFileName] = useState<string>(''); // Состояние для хранения имени файла

    // Обработчик выбора файла
    const handleFileChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            console.log('Файл изменен: ', event.target.files); // Логируем содержимое event.target.files

            const file = event.target.files ? event.target.files[0] : null;
            if (file) {
                console.log('Файл выбран:', file.name); // Логируем выбранный файл
                setFileName(file.name); // Обновляем имя файла
                onFileChange(file); // Вызываем колбек для обработки выбранного файла
            } else {
                console.log('Файл не выбран');
                setFileName(''); // Если файл не выбран, сбрасываем имя файла
                onFileChange(null); // Отправляем null в колбек, если файл не выбран
            }
        },
        [onFileChange]
    );

    // Открытие выбора файла при клике на кнопку

    return (
        <label>
            <div className="file-input-button" style={styles.button}>
                <span style={styles.icon}>
                    <LoadFileIcon />
                </span>
                {fileName ? fileName : 'Выберите файл'}
            </div>

            <input
                id="file-input"
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </label>
    );
};

// Стиль для кнопки и иконки
const styles = {
    button: {
        display: 'inline-flex',
        alignItems: 'center',
        padding: '12px 20px',
        fontSize: '16px',
        fontWeight: 'bold',
        borderRadius: '8px',
        border: 'none',
        backgroundColor: '#2D303E', // Зеленый фон
        color: 'white',
        cursor: 'pointer',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Легкая тень для кнопки
        transition: 'background-color 0.3s',
        whiteSpace: 'nowrap' // Чтобы текст не обрезался
    },
    icon: {
        marginRight: '10px', // Отступ между иконкой и текстом
        fontSize: '20px' // Размер иконки
    },
    label: {
        display: 'block', // Лейбл занимает всю ширину
        fontSize: '14px',
        color: '#ffffff',
        marginBottom: '8px', // Отступ снизу
        fontWeight: 'bold' // Жирный текст для лейбла
    }
};

export default RawFileButton;
