import { useCallback } from 'react';

import { EditForm } from 'ui/EditForm';
import { withAuth } from 'context/Auth';
import withContainer from 'components/Container/withContainer';
import { useParams } from 'react-router-dom';
import Button from 'ui/Button/Button';
import ButtonContainer from 'ui/Button/ButtonContainer';
import FormContainer from 'ui/FormContainer/FormContainer';
import FormField from 'ui/FormField/FormField';
import { Headline } from 'ui/Styled/Styled';
import { getAssignedCourseById } from 'api/courses/courses';
import { SertificateDocModel } from 'api/api';
import { generateCertificate } from 'api/documents/documents';

const Field = FormField<SertificateDocModel>;

const CertificateForm = () => {
    const { id } = useParams();

    const getter = useCallback(() => {
        if (!id) {
            throw new Error(`No assigned course id`);
        }

        return getAssignedCourseById(Number.parseInt(id));
    }, [id]);

    return (
        <EditForm
            getter={getter} // Получаем данные организации
            saver={generateCertificate} // Сохраняем изменения
            successMessage="Свидетельство сформировано"
        >
            {({ handleSubmit, submitting }) => (
                <FormContainer onSubmit={handleSubmit}>
                    <Headline>Свидетельство</Headline>
                    <Field name="image" type="file" label="Фото профиля" />
                    <Field name="number" label="Номер документа" />
                    <Field name="surname" label="Фамилия" />
                    <Field name="name" label="Имя" />
                    <Field name="lastname" label="Отчество" />

                    <Field
                        name="dateStart"
                        label="Дата начала прохождения курса"
                        type="date"
                    />
                    <Field
                        name="dateEnd"
                        label="Дата окончания прохождения курса"
                        type="date"
                    />

                    <Field name="hoursCount" label="Количество часов" />
                    <Field name="course" label="Квалификацияэ" />

                    <ButtonContainer>
                        <Button type="submit" disabled={submitting}>
                            Скачать
                        </Button>
                    </ButtonContainer>
                </FormContainer>
            )}
        </EditForm>
    );
};

export default withAuth(withContainer(CertificateForm));
