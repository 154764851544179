import React, { FC } from 'react';
import styled from 'styled-components';

type ButtonType = 'button' | 'submit' | 'reset';

interface ButtonProps {
    type?: ButtonType;
    disabled?: boolean;
    className?: string;
    children: React.ReactNode;
    onClick?(): void;
    href?: string; // ссылка на файл
    download?: string; // имя файла для сохранения
    size?: 's' | 'm' | 'base';
    target?: string;
}

const size = {
    s: {
        padding: '8px 12px',
        width: 'initial',
        height: 'initial',
        fontSize: '12px'
    },
    m: {
        width: 'initial',
        height: 'initial',
        padding: 'initial',
        fontSize: 'initial'
    },
    base: {
        width: '190px',
        height: '50px',
        padding: 'initial',
        fontSize: '18px'
    }
};

const StyledButton = styled.a<ButtonProps>`
    // Изменяем с <button> на <a>
    display: inline-block;
    text-align: center;
    text-decoration: none;
    width: ${(props) => size[props.size || 'base'].width};
    height: ${(props) => size[props.size || 'base'].height};
    padding: ${(props) => size[props.size || 'base'].padding};
    border-radius: 8px;
    background-color: #718ebf;
    color: #fff;
    font-size: ${(props) => size[props.size || 'base'].fontSize};
    font-weight: 500;
    border-width: 0;
    cursor: pointer;

    &:not(:disabled):hover {
        background-color: #60779e;
    }

    &:not(:disabled):active {
        background-color: #495b79;
    }
`;

const Button: FC<ButtonProps> = ({
    type = 'button',
    disabled,
    children,
    onClick,
    href,
    download,
    size = 'base',
    target = '_blank'
}) => {
    const buttonProps = {
        type,
        onClick,
        href: disabled ? undefined : href, // Ссылка только если не disabled
        download: href && download,
        target: href && target
    };

    return (
        <StyledButton as={href ? 'a' : 'button'} size={size} {...buttonProps}>
            {children}
        </StyledButton>
    );
};

export default Button;
