import { get } from '../../utils/fetch';
import * as Types from './professions.types';

export const getProfessions = async (search?: string) => {
    const response = await get<Types.ProfessionsInfo>('Course/GetProfessions', {
        search
    });
    console.log(response);
    return response ?? []; // Извлекаем массив профессий из поля data
};
