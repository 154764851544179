import { OrganizationId } from 'api/organizations/organizations.types';
import { get, postWithToast, putWithToast } from '../../utils/fetch';
import * as Types from './students.types';

export const getAllStudents = (search?: string) =>
    get<Types.StudentsResponse>('Operator/GetStudentsByOrganizations', {
        search
    });

export const getStudents = (
    search?: string,
    organizationId: OrganizationId = 'current'
) =>
    get<Types.StudentsResponse>(`Organization/${organizationId}/GetStudents`, {
        search
    });

export const createStudent = (props: Types.CreateStudentFormData) =>
    postWithToast('Operator/RegisterStudentForOperator', props);

export const getStudentById = (studentId: string) =>
    get<Types.FullStudentInfo>(`/Operator/GetStudentById/${studentId}`);

export const getAssignCourse = (studentId: string) =>
    get<Types.AssignedCourse[]>(`AssignCourse/GetAssigns/${studentId}`);

export const getMoodle = (studentId: string) =>
    get<Types.MoodleData>(`Moodle/GetLogPas/${studentId}`);

export const getStudentEditData = (studentId: string) =>
    getStudentById(studentId).then((data) => data.student);

export const editStudent = (props: Types.FullStudentInfo) =>
    postWithToast(`Operator/UpdateStudent`, props);
