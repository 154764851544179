export const RouterPaths = {
    Auth: '/auth',
    RegisterUser: '/signup',
    RegisterOrg: '/reg',
    Home: '/',
    Students: '/students', // чё-то походу не нужно это
    FindStudents: '/students/find',
    CreateStudents: '/students/add',
    FindOrganization: '/orgs/find',
    CreateOrganization: '/orgs/add',
    CreateCourse: '/courses/create',
    FindCourse: '/courses/find',
    AssignCourse: '/courses/assign',
    FindComission: '/comissions/find',
    CreateComission: '/comissions/add',
    StudentsDocuments: '/assigned-courses',
    Settings: '/settings',
    Profile: '/profile',
    SetPassword: '/password',
    Operators: '/operators',

    GenerateCertificate: (id?: number) =>
        `/assigned-courses/${id ?? ':id'}/certificate`,
    OrganizationProfile: (id?: string | number) =>
        `/organization/${id ?? ':id'}`,
    OrganizationEdit: (id?: string | number) =>
        `/organization/${id ?? ':id'}/edit`,
    StudentProfile: (id?: Maybe<string>) => `/students/${id ?? ':id'}`,
    CourseEdit: (id?: string | number) => `/courses/${id ?? ':id'}/edit`,
    ComissionPage: (id?: string) => `/comissions/${id ?? ':id'}`,
    StudentEdit: (id?: string | number) => `/students/${id ?? ':id'}/edit`
};
