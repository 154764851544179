import React from 'react';
import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Headline } from '../../ui/Styled/Styled';

const Profile = () => {
    return <Headline>Оператор</Headline>;
};

export default withAuth(withContainer(Profile));
