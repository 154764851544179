import { postWithToast, get, putWithToast } from '../../utils/fetch';
import * as Types from './organizations.types';

export const createOrganization = (props: Types.CreateOrganizationFormData) =>
    postWithToast('Organization/AddOrganization', props);

export const getAllOrganizations = (search?: string) =>
    get<Types.OrganizationsResponse>('Operator/GetOrganizations', { search });

export const getOrganizationById = (organizationId: string) =>
    get<Types.OrganizationFullInfo>(`Organization/${organizationId}`);

export const editOrganization = (props: Types.OrganizationFullInfo) =>
    putWithToast<Types.OrganizationFullInfo>(
        'Organization/UpdateOrganization',
        props
    );
