import DEFAULT_SPRITE_PATH from 'assets/sprite.svg';
import { IconConfig } from './Icon.types';

export { DEFAULT_SPRITE_PATH };

const RAW_ICONS = {
    phone: {
        id: 'i_phone'
    },
    email: {
        id: 'i_email'
    },
    doc: {
        id: 'i_doc'
    },
    copy: {
        id: 'i_copy'
    }
};

export const ICONS: Record<string, IconConfig> = RAW_ICONS;

export type IconKey = keyof typeof RAW_ICONS;
