import { OrganizationId } from 'api/organizations/organizations.types';
import { get, postWithToast, putWithToast } from '../../utils/fetch';
import * as Types from './courses.types';
import { getCurrentOrganizationId } from 'context/User';
import { AssignedCourse } from './courses.types';
import { SertificateDocModel } from 'api/api';

export const createCourse = (props: Types.CreateCourseFormData) =>
    postWithToast('Course/CreateCourse', props);

export const getCourses = (
    search?: string,
    organizationId: OrganizationId = 'current'
) =>
    get<Types.CoursesResponse>(`Course/GetCoursesByOrgId/${organizationId}`, {
        search
    });

export const assignCourse = (props: Types.AssignCourseFormData) =>
    postWithToast('AssignCourse/AddAssignCourse', props);

export const getCourseDateEndLearning = (courseId: number, startDate: string) =>
    get('AssignCourse/GetDateEndLearning', { courseId, startDate });

export const getAssignedCourses = (
    _search?: string,
    organizationId: OrganizationId = getCurrentOrganizationId()
) =>
    get<AssignedCourse[]>(
        `AssignCourse/GetOrganizationAssigns/${organizationId}`
    );

export const getCourseById = (courseId: string) =>
    get<Types.CourseInfo>(`Course/${courseId}`);

export const editCourse = (props: Types.CourseInfo) =>
    putWithToast<Types.CourseInfo>('/Course/UpdateCourse', props);

export const getAssignedCourseById = (id: number) =>
    get<SertificateDocModel>(`DocumentCourse/GetSertificateInfo/${id}`);
