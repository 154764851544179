export const isoDateWithoutTime = (date: string) => date.substring(0, 10);

export function formatDate(date?: unknown) {
    // Проверяем, существует ли дата окончания курса
    if (date) {
        const dateObj = new Date(String(date));

        // Проверяем, является ли дата действительной
        if (!isNaN(dateObj.getTime())) {
            return dateObj.toLocaleDateString('en-GB');
        } else {
            return 'Некорректная дата';
        }
    }

    return 'Дата не указана';
}
