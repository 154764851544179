import { useCallback, useState } from 'react';
import { EditForm } from 'ui/EditForm';
import { withAuth } from 'context/Auth';
import withContainer from 'components/Container/withContainer';
import { useParams } from 'react-router-dom';
import Button from 'ui/Button/Button';
import FormContainer from 'ui/FormContainer/FormContainer';
import FormField from 'ui/FormField/FormField';
import { Headline } from 'ui/Styled/Styled';
import { CreateCourseFormData } from '../../api/courses/courses.types';
import { editCourse, getCourseById } from '../../api/courses/courses';
import { SelectField } from '../../ui/Select';
import { generateOptions } from '../../utils/options';
import ButtonContainer from 'ui/Button/ButtonContainer';
import { OptionProps } from 'ui/Select/Select.types';
import { useEffectOnce } from 'hooks/useEffectOnce';
import { getOrganizationCommitties } from 'api/committies/committies';
import { getOrganizationDisciplines } from 'api/disciplines/disciplines';
import { getProfessions } from 'api/professions/professions';
import { ProfessionsInfo } from 'api/professions/professions.types';

const Select = SelectField<CreateCourseFormData>;
const Field = FormField<CreateCourseFormData>;

const educationTypeOptions = generateOptions('educationTypes');
const assignedQualificationOptions = generateOptions('qualificationTypes');
const issuedDocumentsOptions = generateOptions('issuedDocuments');

const CourseEditForm = () => {
    const { id } = useParams();

    const getter = useCallback(() => {
        if (!id) {
            throw new Error(`No course id`);
        }
        return getCourseById(id);
    }, [id]);
    const [disciplinesOptions, setDisciplinesOptions] = useState<
        OptionProps<number>[]
    >([]);
    const [committiesOptions, setCommittiesOptions] = useState<
        OptionProps<string>[]
    >([]);
    const [professionOptions, setProfessionsOptions] = useState<
        OptionProps<number>[]
    >([]);

    useEffectOnce(async () => {
        setCommittiesOptions(
            (await getOrganizationCommitties()).map((item) => ({
                value: item.id,
                label: item.title
            }))
        );

        setDisciplinesOptions(
            (await getOrganizationDisciplines()).map((item) => ({
                value: item.id,
                label: item.title
            }))
        );
        (async () => {
            const professions = await getProfessions();
            setProfessionsOptions(
                professions.map((item: ProfessionsInfo) => ({
                    value: item.id,
                    label: item.title
                }))
            );
        })();
    });
    return (
        <EditForm
            getter={getter} // Получаем данные организации
            saver={editCourse} // Сохраняем изменения
            successMessage="Курс успешно обновлен"
        >
            {({ handleSubmit, submitting }) => (
                <FormContainer onSubmit={handleSubmit}>
                    <Headline>Редактировать курс</Headline>
                    <Field
                        name="title"
                        label="Название курса"
                        placeholder="Иванов"
                        required
                    />
                    <Select
                        name="professionId"
                        label="Название профессии (из справочника)"
                        placeholder="Выберите профессию"
                        options={professionOptions}
                        required
                    />
                    <Select
                        name="trainingType"
                        label="Вид обучения"
                        options={educationTypeOptions}
                        required
                    />
                    <Select
                        name="assignedQualification"
                        label="Присваемая квалификация"
                        options={assignedQualificationOptions}
                        required
                    />
                    <Select
                        name="disciplineIds"
                        label="Перечень дисциплин"
                        options={disciplinesOptions} //disciplinesOptions <- поставить это когда настроятся запросы
                        required
                        multiple
                    />
                    <Field
                        name="hoursCount"
                        label="Количество часов"
                        placeholder="Введите число"
                        type="number"
                        required
                    />
                    <Select
                        name="issuedDocuments"
                        required
                        multiple
                        label="Выдаваемый документ"
                        options={issuedDocumentsOptions}
                    />
                    <Select // выпадашка
                        name="committeeId"
                        required
                        label="Комиссия"
                        options={committiesOptions} //committiesOptions <- поставить это когда настроятся запросы
                    />
                    <Field
                        name="validityPeriodDocument"
                        label="Срок действия документа (кол-во лет)"
                        placeholder="Введите число"
                        type="number"
                        required
                    />
                    <Field
                        name="price"
                        label="Стоимость обучения"
                        placeholder="Введите стоимость"
                        required
                        min="0"
                        type="number"
                    />
                    <ButtonContainer>
                        <Button type="submit" disabled={submitting}>
                            Создать
                        </Button>
                    </ButtonContainer>
                </FormContainer>
            )}
        </EditForm>
    );
};

export default withAuth(withContainer(CourseEditForm));
