import { FC } from 'react';
import styled from 'styled-components';

interface AvatarProps {
    url?: string;
    size?: 's' | 'm';
}

const size = {
    s: {
        width: '85px',
        height: '85px'
    },
    m: {
        width: '100px',
        height: '100px'
    }
};

export const StyledAvatar = styled.div<AvatarProps>`
    width: ${(props) => size[props.size || 's'].width};
    height: ${(props) => size[props.size || 's'].height};
    box-sizing: border-box;
    border: 1px solid rgb(113, 142, 191);
    border-radius: 6px;
    background-image: url(${(props) => props.url});
    background-repeat: no-repeat;
    background-size: cover;
`;

const Avatar: FC<AvatarProps> = ({ url, size }) => {
    return <StyledAvatar size={size} url={url} />;
};

export default Avatar;
