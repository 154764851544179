import { FileData } from 'api/file/file.types';
import { FC, useCallback } from 'react';
import RawFileButton from './RawFileButton';
import { uploadFile } from 'api/file/file';
import { FetchError } from 'typings/fetch';

type FileButtonProps = {
    onUpload(data: FileData): void;
    onError?(err: FetchError): void;
};

export const FileButton: FC<FileButtonProps> = ({ onUpload, onError }) => {
    const onChange = useCallback(
        (file: File | null) =>
            file &&
            uploadFile(file)(({ data }) => {
                if (data) {
                    onUpload(data);
                }
            }, onError),
        [onUpload, onError]
    );

    return <RawFileButton onFileChange={onChange} />;
};
