const fallbackCopyToClipboard = (text: string) => {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
};

export const copyToClipboard = (value: string) => {
    if (navigator.clipboard) {
        try {
            navigator.clipboard.writeText(value);
        } catch (err) {
            console.error('Ошибка при копировании текста: ', err);
        }
    } else {
        // Альтернативный метод для браузеров, которые не поддерживают API clipboard
        fallbackCopyToClipboard(value);
    }
};
