/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC } from 'react';
import { useAuth } from './Auth.context';
import Skeleton from 'components/Skeleton/Skeleton';

type AuthComponentProps = ChildrenProps & {
    skeleton?: React.ReactNode;
};

const AuthComponent: FC<AuthComponentProps> = ({ children, skeleton }) => {
    const logined = useAuth();

    if (!logined) {
        return <>{skeleton}</> || <Skeleton />;
    }

    return <>{children}</>;
};

export const withAuth =
    (Page: FC<any>, skeleton?: React.ReactNode) =>
    // eslint-disable-next-line react/display-name
    (props: any) => (
        <AuthComponent skeleton={skeleton}>
            <Page {...props} />
        </AuthComponent>
    );
