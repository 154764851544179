/* eslint-disable @typescript-eslint/no-explicit-any */
import Skeleton from 'components/Skeleton/Skeleton';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form, FormRenderProps, RenderableProps } from 'react-final-form';
import { makeEditFormFunc } from 'utils/api';
import { FetchCallback } from 'utils/fetch';

type EditFormProps<
    FormValues extends Record<string, any> = Record<string, any>
> = RenderableProps<FormRenderProps<FormValues>> & {
    // Функция получения значений полей формы (обычно обертка обращения к API с передачей ID объекта)
    getter(): Promise<FormValues>;
    // Компонент, который отображается во время загрузки первичных значений полей
    skeleton?: React.ReactNode;
    // Сообщение об успешности отправки
    successMessage?: string;
    // Функция сохранения данных (обычно обращение к API)
    saver(data: FormValues): FetchCallback<any, FormValues>;
};

export function EditForm<FormValues extends Record<string, any>>({
    getter,
    skeleton,
    saver,
    successMessage,
    ...props
}: EditFormProps<FormValues>): JSX.Element {
    const [initialValues, setInitialValues] = useState<FormValues>();

    useEffect(() => {
        getter().then(setInitialValues);
    }, [getter]);

    const submitFunc = useMemo(
        () => makeEditFormFunc(saver, successMessage),
        [saver, successMessage]
    );

    const handleSubmit = useCallback(
        (data: FormValues) => {
            // onSubmit?.(data, form);
            return submitFunc(data);
        },
        [submitFunc]
    );

    if (!initialValues) {
        return <>{skeleton || <Skeleton />}</>;
    }

    return (
        <Form
            onSubmit={handleSubmit}
            initialValues={initialValues}
            {...props}
        />
    );
}
