import {
    createBrowserRouter,
    Route,
    createRoutesFromElements
} from 'react-router-dom';
import { RouterPaths } from 'helpers/router-paths';

import AuthPage from './pages/auth/Auth';
import HomePage from './pages/Home/Home';
import RegisterUser from './pages/register/User';
import RegisterOrg from './pages/register/Org';
import FindStudents from './pages/students/List';
import CreateStudent from './pages/students/Create';
import CreateOrganization from './pages/organizations/Create';
import OrganizationsList from './pages/organizations/List';
import ComissionsList from './pages/comissions/List';
import CreateComission from './pages/comissions/Create';
import Documents from './pages/documents/List';
import CreateCourse from './pages/courses/Create';
import CourseList from './pages/courses/List';
import Profile from './pages/profile/Profile';
import Operators from './pages/operators/Operators';
import SetPassword from './pages/setPassword/SetPassword';
import ComissionPage from './pages/comissions/Single';
import AssignCoursePage from './pages/courses/Assign';
import ProfileStudent from 'pages/profile/ProfileStudent';
import StudentEditForm from 'pages/profile/editFoms/StudentProfileEdit';
import OperatorEditForm from 'pages/profile/editFoms/OperatorProfileEdit';
import ProfileOrganization from 'pages/profile/ProfileOrganization';
import OrganizationEdit from 'pages/profile/editFoms/OrganizationProfileEdit';
import CourseEditForm from 'pages/courses/Edit';
import Certificate from 'pages/documents/forms/Certificate';
const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route path={RouterPaths.Auth} element={<AuthPage />} />
            <Route path={RouterPaths.RegisterUser} element={<RegisterUser />} />
            <Route path={RouterPaths.RegisterOrg} element={<RegisterOrg />} />

            <Route path={RouterPaths.Home} element={<HomePage />} />
            <Route path={RouterPaths.FindStudents} element={<FindStudents />} />
            <Route
                path={RouterPaths.CreateStudents}
                element={<CreateStudent />}
            />
            <Route
                path={RouterPaths.CreateOrganization}
                element={<CreateOrganization />}
            />
            <Route
                path={RouterPaths.FindOrganization}
                element={<OrganizationsList />}
            />
            <Route path={RouterPaths.CreateCourse} element={<CreateCourse />} />
            <Route path={RouterPaths.FindCourse} element={<CourseList />} />
            <Route
                path={RouterPaths.AssignCourse}
                element={<AssignCoursePage />}
            />
            <Route
                path={RouterPaths.FindComission}
                element={<ComissionsList />}
            />
            <Route
                path={RouterPaths.CreateComission}
                element={<CreateComission />}
            />
            <Route
                path={RouterPaths.ComissionPage()}
                element={<ComissionPage />}
            />
            <Route
                path={RouterPaths.StudentsDocuments}
                element={<Documents />}
            />

            {/* <Route path={RouterPaths.Settings} element={<Settings />} /> */}
            <Route path={RouterPaths.Profile} element={<Profile />} />
            <Route path={RouterPaths.Operators} element={<Operators />} />
            <Route path={RouterPaths.SetPassword} element={<SetPassword />} />
            <Route
                path={RouterPaths.StudentProfile()}
                element={<ProfileStudent />}
            />
            <Route
                path={RouterPaths.OrganizationProfile()}
                element={<ProfileOrganization />}
            />
            <Route
                path={RouterPaths.OrganizationEdit()}
                element={<OrganizationEdit />}
            />
            <Route
                path={RouterPaths.CourseEdit()}
                element={<CourseEditForm />}
            />
            <Route
                path={RouterPaths.GenerateCertificate()}
                element={<Certificate />}
            />
            <Route
                path={RouterPaths.StudentEdit()}
                element={<StudentEditForm />}
            />
            <Route
                path={RouterPaths.StudentEdit()}
                element={<OperatorEditForm />}
            />
        </>
    )
);

export default router;
