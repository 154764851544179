/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react';
import * as S from './Table.styled';
import { getTranslated, TranslateKey, Translates } from 'messages';
import { makeArray } from 'utils/arrays';
import { Link } from 'react-router-dom';
import Skeleton from 'components/Skeleton/Skeleton';
import { ColumnOptions } from './Table.types';
import { isColumnOptions } from './utils';
import { getIn } from 'utils/getIn';

type RowRawProps = { onClick?(): void; link?: string };

export type RowProps<T> = T & RowRawProps;
export type ColumnProps<T> = Partial<Record<Leaves<T>, string | ColumnOptions>>;

type RowFormattedProps<Columns> = RowRawProps &
    Record<keyof Columns, React.ReactNode>;

export type IdProps<
    IdValue = string,
    IdColumn extends string = 'id',
    Data extends Record<string, unknown> = Record<string, unknown>
> = {
    [K in keyof Data]: K extends IdColumn ? IdValue : Data[K];
} & Data;

export type TableProps<
    RowData extends IdProps<IdValue>,
    Columns extends ColumnProps<RowData> = ColumnProps<RowData>,
    IdColumn extends keyof RowData = 'id',
    IdValue extends RowData[IdColumn] = RowData[IdColumn]
> = {
    columns: Columns;
    rows: RowProps<RowData>[] | undefined;
    showHead?: boolean;
    loading?: boolean;
    parseColumns?: Partial<{
        [Column in Leaves<RowData>]: (
            value: DeepIdx<RowData, Column>,
            row: RowData,
            rawValue: DeepIdx<RowData, Column>
        ) => React.ReactNode;
    }>;
    translateColumns?: Partial<Record<keyof Columns, TranslateKey>>;
    noRowsMsg?: React.ReactNode;
    onRowClick?(row: RowData, index: number): void;
    idColumn?: IdColumn;
    onRowSelect?(
        selectedIds: RowData[IdColumn][],
        currentId: RowData[IdColumn]
    ): void;

    linkGetter?: (id: IdValue | undefined, row: RowProps<RowData>) => string;
};

export type ParseTableColumns<
    RowData extends IdProps<IdValue>,
    IdColumn extends keyof RowData = 'id',
    IdValue extends RowData[IdColumn] = RowData[IdColumn]
> = TableProps<RowData>['parseColumns'];

export function Table<
    RowData extends IdProps<IdValue>,
    Columns extends ColumnProps<RowData> = ColumnProps<RowData>,
    IdColumn extends keyof RowData = 'id',
    IdValue extends RowData[IdColumn] = RowData[IdColumn]
>({
    columns,
    rows: rawRows,
    showHead = true,
    parseColumns,
    translateColumns,
    noRowsMsg,
    loading,
    idColumn = 'id' as any,
    linkGetter
}: TableProps<RowData, Columns, IdColumn, IdValue>): JSX.Element {
    const rows = useMemo(
        () =>
            rawRows?.map((row) => {
                const { link, ...formattedRow } = Object.keys(columns).reduce<
                    RowFormattedProps<Columns>
                >((prev, rawKey) => {
                    const key = rawKey as keyof Columns;
                    const value = getIn(row, rawKey as Leaves<RowData>);

                    const formattedValue = translateColumns?.[key]
                        ? makeArray(value)
                              .map((val) =>
                                  getTranslated(
                                      translateColumns[key] as TranslateKey,
                                      val as keyof Translates[TranslateKey]
                                  )
                              )
                              .join(', ')
                        : value;

                    return {
                        ...prev,
                        [key]:
                            parseColumns?.[key]?.(
                                formattedValue as DeepIdx<RowData, any>,
                                row,
                                value as DeepIdx<RowData, any>
                            ) || formattedValue
                    };
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                }, {} as any);

                return {
                    ...formattedRow,
                    link: link || linkGetter?.(row[idColumn] as IdValue, row)
                };
            }),
        [rawRows, columns, linkGetter, idColumn, translateColumns, parseColumns]
    );

    const rowsContent = useMemo(() => {
        return (
            rows?.map((fullRow, index) => {
                const { link, onClick, ...row } = fullRow;

                const content = (
                    <S.Row onClick={onClick}>
                        {Object.keys(columns).map((key: any) => (
                            <S.Cell
                                className={`td-${key}`}
                                data-key={key}
                                key={key}
                            >
                                {(row as any)[key]}
                            </S.Cell>
                        ))}
                    </S.Row>
                );

                if (link) {
                    return (
                        <Link key={index} to={link}>
                            {content}
                        </Link>
                    );
                }

                return <React.Fragment key={index}>{content}</React.Fragment>;
            }) ?? <></>
        );
    }, [rows, columns]);

    return (
        <>
            {loading ? (
                <Skeleton />
            ) : (
                <S.Contaner $columns={columns}>
                    {showHead && (
                        <S.Head>
                            <S.HeadRow>
                                {Object.entries(columns).map(([key, value]) => (
                                    <S.HeadingCell
                                        className={`th-${key}`}
                                        key={key}
                                    >
                                        {isColumnOptions(value)
                                            ? value.title
                                            : String(value)}
                                    </S.HeadingCell>
                                ))}
                            </S.HeadRow>
                        </S.Head>
                    )}
                    {rows?.length ? <S.Body>{rowsContent}</S.Body> : noRowsMsg}
                </S.Contaner>
            )}
        </>
    );
}
