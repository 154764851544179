import { withAuth } from '../../context/Auth';
import * as S from './Profile.styled';
import Button from 'ui/Button/Button';
import * as GlobalStyle from 'ui/Styled/Styled';

import { Link, useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import withContainer from 'components/Container/withContainer';
import { getOrganizationById } from 'api/organizations/organizations';
import { OrganizationFullInfo } from 'api/organizations/organizations.types';
import { PowerTable } from 'ui/PowerTable';

import { ColumnProps, ParseTableColumns } from 'ui/Table';
import { getStudents } from 'api/students/students';
import { StudentInfo } from 'api/students/students.types';
import { RouterPaths } from 'helpers/router-paths';
import { formatDate } from 'utils/date';

const STUDENTS_COLUMNS: ColumnProps<StudentInfo> = {
    fullName: 'ФИО',
    phone: 'Номер телефона',
    birthday: 'Дата рождения',
    email: 'Почта'
};

const parseColumns: ParseTableColumns<StudentInfo> = {
    birthday: formatDate
};

const ProfileOrganization = () => {
    const { id } = useParams();
    const [organization, setStudent] = useState<OrganizationFullInfo | null>(
        null
    ); // Типизируем состояние

    useEffect(() => {
        if (id) {
            getOrganizationById(id).then(setStudent).catch(console.error);
        }
    }, [id]);

    const getOrgStudents = useCallback(
        (search?: string) => {
            if (!Number(id)) {
                throw new Error("No id value or it's not number");
            }

            return getStudents(search, Number(id));
        },
        [id]
    );

    const phone = `${organization?.organizationPhoneNumper || 'Не найдено'}`;
    const email = `${organization?.organizationEmail || 'Не найдено'} `;
    const bankAccountNumber = `${organization?.bankAccountNumber || 'Не найдено'}`;
    const address = `${organization?.postalCode}, ${organization?.region || 'Не найдено'}, ${organization?.city}, ${organization?.street}, ${organization?.house}`;
    const titleOrg = `${organization?.title || 'Не найдено'}`;

    return (
        <>
            <S.ContainerTopContent>
                <S.ContainerLeftContent>
                    <S.ContainerMainInformation>
                        <GlobalStyle.Typography1>
                            {titleOrg}
                        </GlobalStyle.Typography1>
                        <S.WrapperRow>
                            <GlobalStyle.Typography3>
                                Телефон: &nbsp;
                            </GlobalStyle.Typography3>
                            <GlobalStyle.Typography4>
                                {' '}
                                {phone}
                            </GlobalStyle.Typography4>
                        </S.WrapperRow>

                        <S.WrapperRow>
                            <GlobalStyle.Typography3>
                                Банковский счет: &nbsp;
                            </GlobalStyle.Typography3>
                            <GlobalStyle.Typography4>
                                {' '}
                                {bankAccountNumber}
                            </GlobalStyle.Typography4>
                        </S.WrapperRow>

                        <S.WrapperRow>
                            <GlobalStyle.Typography3>
                                Почтовый адрес: &nbsp;
                            </GlobalStyle.Typography3>
                            <GlobalStyle.Typography4>
                                {' '}
                                {address}
                            </GlobalStyle.Typography4>
                        </S.WrapperRow>

                        <S.WrapperRow>
                            <GlobalStyle.Typography3>
                                Эл. почта: &nbsp;
                            </GlobalStyle.Typography3>
                            <GlobalStyle.Typography4>
                                {' '}
                                {email}
                            </GlobalStyle.Typography4>
                        </S.WrapperRow>
                    </S.ContainerMainInformation>
                </S.ContainerLeftContent>
                <S.ContainerRightContent>
                    <Link to={RouterPaths.OrganizationEdit(id)}>
                        <Button size={'s'}>Редактировать</Button>
                    </Link>
                </S.ContainerRightContent>
            </S.ContainerTopContent>
            <S.ContainerField>
                <PowerTable
                    columns={STUDENTS_COLUMNS}
                    getter={getOrgStudents}
                    noRowsMsg="Студенты не найдены"
                    search={true}
                    linkGetter={RouterPaths.StudentProfile}
                    parseColumns={parseColumns}
                />
            </S.ContainerField>
        </>
    );
};

export default withAuth(withContainer(ProfileOrganization));
