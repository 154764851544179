import {
    SkeletonWrapper,
    SkeletonCell,
    SkeletonTopCell,
    SkeletonMiddleCell
} from './Skeleton.styled';

const Skeleton = () => (
    <SkeletonWrapper>
        {/* Верхняя плашка (3vh, 40%, по центру) */}
        <SkeletonTopCell />

        {/* Средняя плашка (4vh, 100%) */}
        <SkeletonMiddleCell />

        {/* Основные плашки */}
        {Array(8)
            .fill(0)
            .map((_, index) => (
                <SkeletonCell
                    key={index}
                    style={{
                        width: '100%',
                        height: '90px',
                        marginBottom: '8px'
                    }}
                />
            ))}
    </SkeletonWrapper>
);

export default Skeleton;
