import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Headline } from '../../ui/Styled/Styled';
import FormContainer from '../../ui/FormContainer/FormContainer';
import FormField from '../../ui/FormField/FormField';
import { CreateStudentFormData } from '../../api/students/students.types';
import { Form } from 'react-final-form';
import Button from '../../ui/Button/Button';

import ButtonContainer from 'ui/Button/ButtonContainer';

import { InlineContainer } from 'ui/InlineContainer/InlineContainer.styled';
import { makeCreateFormFunc } from 'utils/api';
import { createStudent } from 'api/students/students';

const Field = FormField<CreateStudentFormData>;

const handleSubmit = makeCreateFormFunc(createStudent, 'Студент создан!');

const CreateStudents = () => {
    return (
        <>
            <Headline>Создать студента</Headline>
            <Form onSubmit={handleSubmit}>
                {({ handleSubmit, submitting }) => (
                    <FormContainer onSubmit={handleSubmit}>
                        <Field
                            name="surname"
                            label="Фамилия"
                            placeholder="Иванов"
                            required
                        />
                        <Field
                            name="name"
                            label="Имя"
                            placeholder="Иван"
                            required
                        />
                        <Field
                            name="lastname"
                            label="Отчество"
                            placeholder="Иванович"
                            required
                        />
                        <Field
                            name="birthday"
                            label="Дата рождения"
                            placeholder="23.08.1999"
                            required
                        />
                        <Field
                            name="phone"
                            type="tel"
                            label="Номер телефона"
                            placeholder="+79999999999"
                            required
                        />
                        <Field
                            name="email"
                            type="email"
                            label="E-mail"
                            placeholder="example@kursoved.pro"
                            required
                        />

                        <Field name="region" required label="Регион" />
                        <Field name="city" required label="Город" />
                        <Field name="street" required label="Улица" />
                        <Field name="house" required label="Дом" />
                        <Field name="flat" required label="Квартира" />
                        <Field name="postalCode" required label="Индекс" />
                        <Field
                            name="placeWork"
                            required
                            label="Организация"
                            placeholder="Название организации"
                        />
                        <Field
                            name="organizationId"
                            required
                            label="Организация ID"
                            placeholder="Название организации"
                        />
                        <Field
                            name="qualification"
                            required
                            label="Специальность"
                            placeholder="Специальность..."
                        />
                        <Field
                            name="rank"
                            required
                            label="Разряд"
                            placeholder="Текст"
                        />
                        <Field
                            name="password"
                            required
                            label="Пароль"
                            placeholder="Текст"
                        />
                        <Field
                            name="confirmPassword"
                            required
                            label="Повторите пароль"
                            placeholder="Текст"
                        />
                        <Field type="file" name="avatar" />

                        <InlineContainer>
                            <Field
                                name="passportSeries"
                                required
                                label="Паспорт"
                                placeholder="Серия паспорта"
                            />
                            <Field
                                name="passportNumber"
                                required
                                label="&nbsp;"
                                placeholder="Номер паспорта"
                            />
                            <Field type="file" name="passport" />
                        </InlineContainer>

                        <InlineContainer>
                            <Field
                                name="snilsNumber"
                                required
                                label="СНИЛС"
                                placeholder="Номер снилс"
                            />
                            <Field type="file" name="snils" />
                        </InlineContainer>
                        <InlineContainer>
                            <Field
                                name="diplomNumber"
                                required
                                label="Документ об образовании"
                                placeholder="Номер диплома"
                            />
                            <Field type="file" name="educationalDocument" />
                        </InlineContainer>
                        <InlineContainer>
                            <Field
                                name="qualification"
                                required
                                label="Документ о повышении квалификации"
                                placeholder="Номер документа"
                            />
                            <Field type="file" name="qualificationDocument" />
                        </InlineContainer>
                        <ButtonContainer>
                            <Button type="submit" disabled={submitting}>
                                Создать
                            </Button>
                        </ButtonContainer>
                    </FormContainer>
                )}
            </Form>
        </>
    );
};

export default withAuth(withContainer(CreateStudents));
