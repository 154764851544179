import { FC } from 'react';
import styled from 'styled-components';
import { ICONS, DEFAULT_SPRITE_PATH, IconKey } from './constants';

type IconProps = {
    icon: IconKey;
    fill?: string;
    className?: string;
    size?: 's' | 'm';
    onClick?(): void;
};

const size = {
    s: {
        width: '12px',
        height: '16px'
    },
    m: {
        width: '24px',
        height: '24px'
    }
};

const StyledIconSvg = styled.svg<Omit<IconProps, 'icon'>>`
    width: ${(props) => size[props.size || 'm'].width};
    height: ${(props) => size[props.size || 'm'].height};
    margin-left: 3px;
    margin-top: 3px;
`;
const StyledIconButton = styled.button`
    cursor: pointer;
    padding: 0px;
`;

const Icon: FC<IconProps> = ({
    icon,
    fill = 'none',
    className,
    size,
    onClick,
    ...rest
}) => {
    const iconProps = ICONS[icon];

    const href = `${iconProps.spritePath || DEFAULT_SPRITE_PATH}#${iconProps.id}`;

    return (
        <>
            {onClick ? (
                <StyledIconButton onClick={onClick}>
                    <StyledIconSvg
                        size={size}
                        fill={fill}
                        className={className}
                        {...rest}
                    >
                        <use href={href} />
                    </StyledIconSvg>
                </StyledIconButton>
            ) : (
                <StyledIconSvg
                    size={size}
                    fill={fill}
                    className={className}
                    {...rest}
                >
                    <use href={href} />
                </StyledIconSvg>
            )}
        </>
    );
};

export default Icon;
