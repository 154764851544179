import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { getAllOrganizations } from '../../api/organizations/organizations';
import { OrganizationInfo } from '../../api/organizations/organizations.types';
import { ColumnProps } from '../../ui/Table';
import { PowerTable } from 'ui/PowerTable';
import { Headline } from 'ui/Styled/Styled';
import { RouterPaths } from 'helpers/router-paths';

type OrganizatonRowProps = Pick<OrganizationInfo, 'title' | 'inn'>;

const ORGANIZATIONS_COLUMNS: ColumnProps<OrganizatonRowProps> = {
    title: 'Название организации',
    inn: 'ИНН'
};

const OrganizatonList = () => {
    return (
        <>
            <Headline>Организации</Headline>
            <PowerTable
                columns={ORGANIZATIONS_COLUMNS}
                getter={getAllOrganizations}
                noRowsMsg="Организации не найдены"
                search={false}
                showHead={false}
                linkGetter={RouterPaths.OrganizationProfile}
            />
        </>
    );
};

export default withAuth(withContainer(OrganizatonList));
