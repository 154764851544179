import styled, { css } from 'styled-components';
import { Container as InputContainer } from '../Input/Input.styled';
import RawInput from '../Input/Input';
import DropdownIcon from '../../assets/dropdown.png';
import CheckboxIcon from '../../assets/checkbox-unchecked.png';
import CheckedIcon from '../../assets/checked.png';

export const Container = styled.div`
    position: relative;

    margin-bottom: 14px;

    & ${InputContainer} {
        margin-bottom: 0;
    }
`;

export const DropdownBase = styled.div`
    display: flex;
    flex-flow: column;
    gap: 4px;
    padding: 8px 6px;
    background: #2d303e;
    border: 1px solid rgb(57, 60, 73);
    /* shadow-m */
    box-shadow:
        0px 5px 15px 0px rgba(0, 0, 0, 0.08),
        0px 15px 35px -5px rgba(17, 24, 38, 0.15),
        0px 0px 0px 1px rgba(152, 161, 179, 0.1);
    border-radius: 8px;

    margin-top: 6px;
`;

export const Dropdown = styled(DropdownBase)<SOpenProps>`
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10;
    max-height: 300px;
    overflow-y: auto;

    ${({ $isOpen }) =>
        !$isOpen &&
        css`
            display: none;
        `}
`;

export const DropdownItem = styled.div<SActiveProps>`
    padding: 4px 10px;
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 2%;
    border-radius: 4px;
    cursor: pointer;
    position: relative;

    ${({ $active }) =>
        $active &&
        css`
            background: rgb(113, 142, 191);
            color: #fff;
        `}

    &::after {
        top: 0;
        content: ' ';
        width: 16px;
        height: 100%;
        position: absolute;
        right: 10px;

        ${({ $active }) =>
            $active &&
            css`
                background-image: url(${CheckedIcon});
            `}

        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center;
    }
`;

export const MultipleDropdownItem = styled(DropdownItem)<SActiveProps>`
    &::after {
        top: 0;
        content: ' ';
        width: 16px;
        height: 100%;
        position: absolute;
        right: 10px;

        ${({ $active }) =>
            !$active &&
            css`
                background-image: url(${CheckboxIcon});
            `}

        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: center;
    }
`;

export const Input = styled(RawInput)<SOpenProps>`
    cursor: pointer !important;

    &::after {
        content: ' ';
        background: url(${DropdownIcon}) no-repeat;
        width: 9px;
        height: 100%;
        position: absolute;
        right: 18px;
        background-size: 100% auto;
        background-position: center;

        transition: transform 0.2s;
        ${({ $isOpen }) =>
            $isOpen &&
            css`
                transform: rotate(180deg);
            `}
    }
`;
