import React, { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { SubmissionError } from '../../typings/fetch';
import * as S from './Input.styled';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type InputProps = FieldRenderProps<any, HTMLElement, any> &
    Partial<HTMLInputElement> & {
        label?: string;
        rightContent?: React.ReactNode;
    };

const Input: FC<InputProps> = ({
    label,
    required,
    minLength,
    maxLength,
    placeholder,
    meta,
    className,
    rightContent,
    ...props
}) => {
    const extractedProps = {
        required,
        minLength,
        maxLength
    };

    return (
        <S.Container>
            <S.Label>{label}</S.Label>
            <S.InputRow>
                <S.InputContainer className={className}>
                    <S.Input
                        placeholder={placeholder ?? label}
                        {...extractedProps}
                        {...props.input}
                    />
                </S.InputContainer>
                {rightContent}
            </S.InputRow>
            {meta.touched &&
                (meta.error ||
                    (meta.submitError && !meta.dirtySinceLastSubmit)) && (
                    <S.Meta>
                        {meta.error ||
                            meta.submitError.map(
                                ({ code, message }: SubmissionError) => (
                                    <div key={code}>{message}</div>
                                )
                            )}
                    </S.Meta>
                )}
        </S.Container>
    );
};

export default Input;
