import { FileData } from 'api/file/file.types';
import { FC } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FileButton } from './FileButton';
import * as S from 'ui/Input/Input.styled';

type FileButtonProps = FieldRenderProps<FileData> & {
    label?: string;
};

export const FileField: FC<FileButtonProps> = ({ input, label }) => {
    const content = <FileButton onUpload={input.onChange} />;

    if (!label) {
        return content;
    }

    return (
        <S.Container>
            <S.Label>{label}</S.Label>
            {content}
        </S.Container>
    );
};
