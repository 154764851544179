import { useCallback } from 'react';
import { EditForm } from 'ui/EditForm';
import { withAuth } from 'context/Auth';
import withContainer from 'components/Container/withContainer';
import { useParams } from 'react-router-dom';
import Button from 'ui/Button/Button';
import ButtonContainer from 'ui/Button/ButtonContainer';
import FormContainer from 'ui/FormContainer/FormContainer';
import FormField from 'ui/FormField/FormField';
import { Headline } from 'ui/Styled/Styled';
import { editStudent, getStudentById } from 'api/students/students';
import {
    CreateStudentFormData,
    FullStudentInfo
} from 'api/students/students.types';
import { InlineContainer } from 'ui/InlineContainer/InlineContainer.styled';

const Field = FormField<FullStudentInfo>;

const StudentEditForm = () => {
    const { id } = useParams();

    const getter = useCallback(() => {
        if (!id) {
            throw new Error(`No student id`);
        }

        return getStudentById(id);
    }, [id]);

    return (
        <EditForm
            getter={getter} // Получаем данные организации
            saver={editStudent} // Сохраняем изменения
            successMessage="Профиль успешно обновлен"
        >
            {({ handleSubmit, submitting }) => (
                <FormContainer onSubmit={handleSubmit}>
                    <Headline>Редактировать студента</Headline>
                    <Field
                        name="user.surname"
                        label="Фамилия"
                        placeholder="Иванов"
                        required
                    />
                    <Field
                        name="user.name"
                        label="Имя"
                        placeholder="Иван"
                        required
                    />
                    <Field
                        name="user.lastname"
                        label="Отчество"
                        placeholder="Иванович"
                        required
                    />
                    <Field
                        name="birthday"
                        label="Дата рождения"
                        placeholder="23.08.1999"
                        required
                    />
                    <Field
                        name="user.phone"
                        type="tel"
                        label="Номер телефона"
                        placeholder="+79999999999"
                        required
                    />
                    <Field
                        name="user.email"
                        type="email"
                        label="E-mail"
                        placeholder="example@kursoved.pro"
                        required
                    />

                    <Field name="region" required label="Регион" />
                    <Field name="city" required label="Город" />
                    <Field name="street" required label="Улица" />
                    <Field name="house" required label="Дом" />
                    <Field name="flat" required label="Квартира" />
                    <Field name="postalCode" required label="Индекс" />
                    <Field
                        name="placeWork"
                        required
                        label="Организация"
                        placeholder="Название организации"
                    />
                    <Field
                        name="student.organizationId"
                        required
                        label="Организация ID"
                        placeholder="Название организации"
                    />
                    <Field
                        name="qualification"
                        required
                        label="Специальность"
                        placeholder="Специальность..."
                    />
                    <Field
                        name="rank"
                        required
                        label="Разряд"
                        placeholder="Текст"
                    />
                    <Field type="file" name="avatar" />

                    <InlineContainer>
                        <Field
                            name="passportSeries"
                            required
                            label="Паспорт"
                            placeholder="Серия паспорта"
                        />
                        <Field
                            name="passportNumber"
                            required
                            label="&nbsp;"
                            placeholder="Номер паспорта"
                        />
                        <Field type="file" name="passport" />
                    </InlineContainer>

                    <InlineContainer>
                        <Field
                            name="snilsNumber"
                            required
                            label="СНИЛС"
                            placeholder="Номер снилс"
                        />
                        <Field type="file" name="snils" />
                    </InlineContainer>
                    <InlineContainer>
                        <Field
                            name="diplomNumber"
                            required
                            label="Документ об образовании"
                            placeholder="Номер диплома"
                        />
                        <Field type="file" name="educationalDocument" />
                    </InlineContainer>
                    <InlineContainer>
                        <Field
                            name="qualification"
                            required
                            label="Документ о повышении квалификации"
                            placeholder="Номер документа"
                        />
                        <Field type="file" name="qualificationDocument" />
                    </InlineContainer>
                    <ButtonContainer>
                        <Button type="submit" disabled={submitting}>
                            Создать
                        </Button>
                    </ButtonContainer>
                </FormContainer>
            )}
        </EditForm>
    );
};

export default withAuth(withContainer(StudentEditForm));
