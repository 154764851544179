import { HTMLInputTypeAttribute } from 'react';
import styled from 'styled-components';
import { themeable } from 'themes/utils';

export const Input = styled.input<{ type?: HTMLInputTypeAttribute }>`
    /* Base / Dark Line */
    border: 1px solid rgb(57, 60, 73);
    border-radius: 8px;

    /* Base / Form BG */
    background: ${({ theme }) => theme.input.background};
    padding: 14px;
    color: inherit;

    font-size: 14px;
    line-height: 140%;
    width: 100%;
    box-sizing: border-box;
    cursor: inherit;
`;

export const Label = styled.div`
    /* Body / Normal - Medium */
    color: ${themeable('textColor')}
    font-size: 14px;
    font-weight: 500;
    line-height: 130%;
`;

export const Container = styled.label`
    display: flex;
    flex-flow: column;
    gap: 5px;
    width: 100%;
    position: relative;
    margin-bottom: 14px;

    /* Body / Normal - Regular */
    color: rgb(255, 255, 255);
`;

export const Meta = styled.div`
    font-size: 13px;
    position: absolute;
    top: 100%;
    margin-top: 6px;
`;

export const InputRow = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 20px;
`;

export const InputContainer = styled.div`
    flex: 1;
    position: relative;
    cursor: text;
`;
