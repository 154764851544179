import React, { HTMLInputTypeAttribute } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';

import Input from '../Input/Input';
import styled from 'styled-components';
import { LoadFileIcon } from '../Menu/assets/ItemsIcons';
import { ValidatorsProps } from './FormField.types';
import { useValidators } from '../../hooks/useValidators';
import { FileField } from 'ui/FileButton/FileField';

// Контейнер для поля и кнопки
const Wrapper = styled.div`
    display: flex;
    flex-direction: row; /* Расположение элементов в строку */
    flex-wrap: nowrap; /* Элементы не переносятся */
    gap: 10px; /* Расстояние между элементами */
    align-content: stretch; /* По умолчанию */
    justify-content: center; /* Центровка по горизонтали */
    align-items: baseline; /* Выравнивание по базовой линии текста */
    width: 100%;
`;

const StyledButton = styled.button`
    border-radius: 8px;
    background: rgb(113, 142, 191);
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    border-width: 0;
    max-height: 50px;
    padding: 14px;
    min-width: 100px;

    &:hover {
        background: rgb(93, 122, 171);
    }

    &:active {
        background: rgb(73, 102, 151);
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

export type FormFieldProps<T> = Omit<
    Partial<HTMLInputElement>,
    'children' | 'type'
> &
    Omit<FieldRenderProps<unknown, HTMLElement, unknown>, 'type'> &
    ValidatorsProps & {
        type?: HTMLInputTypeAttribute;
        name: Paths<T>;

        showButton?: boolean; // Пропс для отображения кнопки
        buttonText?: string; // Текст кнопки

        rightContent?: React.ReactNode;

        onButtonClick?: () => void; // Обработчик клика
        // Используй validators
        validate?: undefined;
    };

/**
 * Компонент для создания полей в форме.
 * @template T Тип данных формы.
 * @param {FormFieldProps<T>} props - Пропсы поля.
 * @returns {JSX.Element} - Отображение поля в форме.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function FormField<T = any>({
    required,
    validators: rawValidators,
    component,
    showButton = false, // Показывать ли кнопку
    // buttonText = "Нажать", // Текст кнопки
    onButtonClick, // Обработчик клика
    type,
    ...props
}: FormFieldProps<T>) {
    const validators = useValidators(rawValidators, { required });

    let finalComponent = Input;

    if (type === 'file') {
        finalComponent = FileField;
    }

    /**
     * Отображение поля в форме.
     * @returns {JSX.Element}
     */
    return (
        <Wrapper>
            <Field
                required={required}
                validate={validators}
                component={component || finalComponent}
                type={type || 'text'}
                {...props}
            />
            {showButton && (
                <StyledButton
                    type="button"
                    onClick={onButtonClick}
                    disabled={false} // Вы можете контролировать состояние кнопки
                >
                    <LoadFileIcon />
                </StyledButton>
            )}
        </Wrapper>
    );
}

export default FormField;
