import { useState } from 'react';
import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Headline } from '../../ui/Styled/Styled';
import FormContainer from '../../ui/FormContainer/FormContainer';
import FormField from '../../ui/FormField/FormField';
import { CreateCourseFormData } from '../../api/courses/courses.types';
import { Form } from 'react-final-form';
import { createCourse } from '../../api/courses/courses';
import Button from '../../ui/Button/Button';
import { SelectField } from '../../ui/Select';
import { generateOptions } from '../../utils/options';
import { useEffectOnce } from '../../hooks/useEffectOnce';
import { getOrganizationCommitties } from '../../api/committies/committies';
import { OptionProps } from '../../ui/Select/Select.types';
import { getOrganizationDisciplines } from 'api/disciplines/disciplines';
import { makeCreateFormFunc } from 'utils/api';
import ButtonContainer from 'ui/Button/ButtonContainer';
import { getProfessions } from 'api/professions/professions';
import { ProfessionsInfo } from 'api/professions/professions.types';

const Field = FormField<CreateCourseFormData>;
const Select = SelectField<CreateCourseFormData>;

const educationTypeOptions = generateOptions('educationTypes');
const assignedQualificationOptions = generateOptions('qualificationTypes');
const issuedDocumentsOptions = generateOptions('issuedDocuments');

const handleFormSubmit = makeCreateFormFunc(createCourse, 'Курс создан!');

const CreateCourse = () => {
    const [committiesOptions, setCommittiesOptions] = useState<
        OptionProps<string>[]
    >([]);
    const [disciplinesOptions, setDisciplinesOptions] = useState<
        OptionProps<number>[]
    >([]);

    const [professionOptions, setProfessionsOptions] = useState<
        OptionProps<number>[]
    >([]);

    useEffectOnce(async () => {
        setCommittiesOptions(
            (await getOrganizationCommitties()).map((item) => ({
                value: item.id,
                label: item.title
            }))
        );

        setDisciplinesOptions(
            (await getOrganizationDisciplines()).map((item) => ({
                value: item.id,
                label: item.title
            }))
        );
        (async () => {
            const professions = await getProfessions();
            setProfessionsOptions(
                professions.map((item: ProfessionsInfo) => ({
                    value: item.id,
                    label: item.title
                }))
            );
        })();
    });

    return (
        <>
            <Headline>Создать курс</Headline>
            <Form onSubmit={handleFormSubmit}>
                {({ handleSubmit, submitting }) => (
                    <FormContainer onSubmit={handleSubmit}>
                        <Field
                            name="title"
                            label="Название курса"
                            placeholder="Иванов"
                            required
                        />
                        <Select
                            name="professionId"
                            label="Название профессии (из справочника)"
                            placeholder="Выберите профессию"
                            options={professionOptions}
                            required
                        />
                        <Select
                            name="trainingType"
                            label="Вид обучения"
                            options={educationTypeOptions}
                            required
                        />
                        <Select
                            name="assignedQualification"
                            label="Присваемая квалификация"
                            options={assignedQualificationOptions}
                            required
                        />
                        <Select
                            name="disciplineIds"
                            label="Перечень дисциплин"
                            options={disciplinesOptions}
                            required
                            multiple
                        />
                        <Field
                            name="hoursCount"
                            label="Количество часов"
                            placeholder="Введите число"
                            type="number"
                            required
                        />
                        <Select
                            name="issuedDocuments"
                            required
                            multiple
                            label="Выдаваемый документ"
                            options={issuedDocumentsOptions}
                        />
                        <Select // выпадашка
                            name="committeeId"
                            required
                            label="Комиссия"
                            options={committiesOptions}
                        />
                        <Field
                            name="validityPeriodDocument"
                            label="Срок действия документа (кол-во лет)"
                            placeholder="Введите число"
                            type="number"
                            required
                        />
                        <Field
                            name="price"
                            label="Стоимость обучения"
                            placeholder="Введите стоимость"
                            required
                            min="0"
                            type="number"
                        />
                        <ButtonContainer>
                            <Button type="submit" disabled={submitting}>
                                Создать
                            </Button>
                        </ButtonContainer>
                    </FormContainer>
                )}
            </Form>
        </>
    );
};

export default withAuth(withContainer(CreateCourse));
