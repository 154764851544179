import * as GlobalStyle from 'ui/Styled/Styled';
import * as S from './Profile.styled';
import Button from 'ui/Button/Button';
import TabMenu from 'ui/TabMenu/TabMenu';
import { PowerTable } from 'ui/PowerTable';
import { ColumnProps } from 'ui/Table';
import { OrganizationInfo } from 'api/organizations/organizations.types';
import { getAllOrganizations } from 'api/organizations/organizations';
import { useState, useEffect } from 'react';
import Avatar from 'ui/Avatar/Avatar';
import Skeleton from 'components/Skeleton/Skeleton';
import { getCurrentUser, getCurrentOrganization } from '../../api/users/users';
import { getCurrentOperators } from '../../api/organizations/operators';
import { CurrentOperator } from 'api/organizations/operators.types';
import { logoutUser } from 'api/auth/auth';
import Tabs from 'ui/Tabs/Tabs';
import { RouterPaths } from 'helpers/router-paths';

type OrganizatonRowProps = Pick<OrganizationInfo, 'title' | 'inn'>;

const ORGANIZATIONS_COLUMNS: ColumnProps<OrganizatonRowProps> = {
    title: 'Название организации',
    inn: 'ИНН'
};

const OPERATORS_COLUMNS: ColumnProps<CurrentOperator> = {
    fullName: 'ФИО',
    email: 'Email'
};

const ProfileOperator = () => {
    const [userData, setUserData] = useState<{
        fullName: string;
        email: string;
        organizationId?: number | null;
    } | null>(null);

    const fetchUserData = async () => {
        try {
            const response = await getCurrentUser();

            // Лог полного ответа от сервера
            // console.log('Ответ от сервера:', response);

            // Проверяем, есть ли данные напрямую в объекте
            if (!response) {
                // console.error('Ошибка: Данные пользователя отсутствуют');
                setUserData(null);
                return;
            }

            // Данные приходят напрямую, без вложенного объекта `data`
            const serverData = response;

            // Лог данных для проверки
            // console.log('Извлеченные данные пользователя:', serverData);

            const fullName = `${serverData.surname} ${serverData.name} ${serverData.lastname}`;
            const email = serverData.email || 'Не указано';
            const organizationId =
                serverData.operator?.currentOrganizationId || null;

            // Лог формируемого объекта
            // console.log('Формируемый объект пользователя:', {
            //     fullName,
            //     email,
            //     organizationId,
            // });

            setUserData({
                fullName,
                email,
                organizationId
            });
        } catch (error) {
            // console.error('Ошибка при загрузке данных пользователя:', error);
            setUserData(null);
        }
    };

    // получаем данные по текущей организации
    const [orgData, setCurrentOrganization] = useState<{
        title: string;
    } | null>(null);
    const fetchCurrentOrganization = async () => {
        try {
            // Получаем ответ от API
            const response = await getCurrentOrganization();

            // Лог полного ответа от сервера
            //console.log('Ответ от сервера:', response);

            // Проверяем наличие ключа `title` в самом объекте
            if (!response || !response.title) {
                //console.error('Ошибка: название организации отсутствует в ответе');
                setCurrentOrganization(null);
                return;
            }

            // Извлекаем название организации
            const title = response.title;

            //console.log('Название организации:', title);

            // Устанавливаем данные в state
            setCurrentOrganization({ title });
        } catch (error) {
            //console.error('Ошибка при загрузке данных организации:', error);
            setCurrentOrganization(null);
        }
    };

    useEffect(() => {
        fetchCurrentOrganization(), fetchUserData();
    }, []);

    if (!userData) {
        return <Skeleton />;
    }

    return (
        <>
            <S.ContainerTopContent>
                <S.ContainerLeftContent>
                    <Avatar />
                    <S.ContainerMainInformation>
                        <GlobalStyle.Typography1>
                            {userData.fullName}
                        </GlobalStyle.Typography1>
                        <GlobalStyle.Typography3>
                            Почта: {userData.email}
                        </GlobalStyle.Typography3>
                        {userData.organizationId ? (
                            <GlobalStyle.Typography3>
                                Организация: {orgData?.title}
                            </GlobalStyle.Typography3>
                        ) : (
                            <GlobalStyle.Typography3>
                                Организация не указана
                            </GlobalStyle.Typography3>
                        )}
                    </S.ContainerMainInformation>
                </S.ContainerLeftContent>
                <S.ContainerRightContent>
                    <Button size={'s'}>Редактировать</Button>
                    <Button size="s" onClick={logoutUser}>
                        Выход
                    </Button>
                </S.ContainerRightContent>
            </S.ContainerTopContent>

            <Tabs
                tabs={[
                    {
                        label: 'Операторы',
                        content: (
                            <PowerTable
                                columns={OPERATORS_COLUMNS}
                                getter={getCurrentOperators}
                                noRowsMsg="Операторы не найдены"
                                search={false}
                            />
                        )
                    },
                    {
                        label: 'Организации',
                        content: (
                            <PowerTable
                                columns={ORGANIZATIONS_COLUMNS}
                                getter={getAllOrganizations}
                                noRowsMsg="Организации не найдены"
                                search={false}
                                linkGetter={RouterPaths.OrganizationProfile}
                            />
                        )
                    }
                ]}
            />
        </>
    );
};

export default ProfileOperator;
