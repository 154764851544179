import { withAuth } from '../../context/Auth';
import withContainer from '../../components/Container/withContainer';
import { Headline } from '../../ui/Styled/Styled';
import FormContainer from '../../ui/FormContainer/FormContainer';
import FormField from '../../ui/FormField/FormField';
import { CreateOrganizationFormData } from '../../api/organizations/organizations.types';
import { Form } from 'react-final-form';
import { createOrganization } from '../../api/organizations/organizations';
import Button from '../../ui/Button/Button';
import { makeCreateFormFunc } from 'utils/api';
import ButtonContainer from 'ui/Button/ButtonContainer';

const Field = FormField<CreateOrganizationFormData>;

const handleFormSubmit = makeCreateFormFunc(
    createOrganization,
    'Организация создана!'
);

const CreateOrganization = () => {
    return (
        <>
            <Headline>Создать организацию</Headline>
            <Form onSubmit={handleFormSubmit}>
                {({ handleSubmit, submitting }) => (
                    <FormContainer onSubmit={handleSubmit}>
                        <Field
                            name="title"
                            label="Название организации"
                            placeholder='ООО "Курсовед"'
                            required
                        />
                        <Field
                            name="phone"
                            type="tel"
                            label="Номер телефона"
                            placeholder="+79999999999"
                            required
                        />
                        <Field
                            name="email"
                            type="email"
                            label="E-mail"
                            placeholder="example@kursoved.pro"
                            required
                        />
                        <Field
                            name="inn"
                            type="number"
                            required
                            label="ИНН"
                            placeholder="ИНН"
                        />
                        <Field
                            name="postAddress"
                            required
                            label="Почтовый адрес"
                            placeholder="ул. Ленина, д. 10, кв. 15 г. Москва Российская Федерация 101000"
                        />
                        <Field
                            name="region"
                            required
                            label="Регион"
                            placeholder="Сургутская обл."
                        />
                        <Field
                            name="city"
                            required
                            label="Город"
                            placeholder="г. Сургут"
                        />
                        <Field
                            name="street"
                            required
                            label="Улица"
                            placeholder="ул. Ленина"
                        />
                        <Field
                            name="house"
                            required
                            label="Дом"
                            placeholder="д. 51"
                        />
                        <Field
                            name="postalCode"
                            type="number"
                            required
                            label="Почтовый индекс"
                            placeholder="101000"
                        />
                        <Field
                            name="bankAccountNumber"
                            type="number"
                            maxLength={20}
                            minLength={20}
                            required
                            label="Номер банковского счета (для выставления счетов)"
                            placeholder="40702810200210000237"
                        />
                        <ButtonContainer>
                            <Button type="submit" disabled={submitting}>
                                Создать
                            </Button>
                        </ButtonContainer>
                    </FormContainer>
                )}
            </Form>
        </>
    );
};

export default withAuth(withContainer(CreateOrganization));
