import { saveAs } from 'file-saver';
import { toast } from 'react-toastify';

export const downloadFile = async (fileUrl: string, fileName: string) => {
    try {
        const response = await fetch(fileUrl);
        if (!response.ok) {
            throw new Error(`Ошибка загрузки файла: ${response.statusText}`);
        }
        const blob = await response.blob();
        saveAs(blob, fileName);
        toast.success(`Файл "${fileName}" успешно скачан!`);
    } catch (error) {
        console.error('Ошибка при скачивании файла:', error);
        toast.error('Ошибка при скачивании файла. Попробуйте снова.');
    }
};

export default downloadFile;
