import { FC, useCallback } from 'react';
import Button from 'ui/Button/Button';
import Icon from 'ui/Icon/Icon';
import * as S from './Profile.styled';
import { copyToClipboard } from 'utils/copy';
import downloadFile from 'utils/download';
import { DataRowProps, MainDataRowProps } from './Profile.types';
import { Typography3, Typography4, Typography2 } from 'ui/Styled/Styled';

export const HeaderDataRow: FC<DataRowProps> = ({ label, value }) => {
    return (
        <S.WrapperRow>
            <Typography3>
                {label}:<Typography4>{value || '—'}</Typography4>
            </Typography3>
        </S.WrapperRow>
    );
};

export const MainDataRow: FC<MainDataRowProps> = ({
    label,
    value,
    icon,
    downloadUrl,
    downloadFileTitle
}) => {
    const copyHandler = useCallback(
        () => value && copyToClipboard(String(value)),
        [value]
    );

    const downloadHandler = useCallback(
        () =>
            downloadUrl &&
            downloadFile(downloadUrl, downloadFileTitle || label),
        [downloadUrl, label, downloadFileTitle]
    );

    return (
        <S.InformationRow>
            <S.InformationData>
                <Icon icon={icon} />
                <S.InformationRowInfo>
                    <Typography2>{label}</Typography2>
                    <Typography4>
                        {value || '—'}
                        {value && (
                            <Icon
                                icon="copy"
                                size={'s'}
                                onClick={copyHandler}
                            />
                        )}
                    </Typography4>
                </S.InformationRowInfo>
            </S.InformationData>
            {downloadUrl && (
                <Button size="s" onClick={downloadHandler}>
                    Скачать
                </Button>
            )}
        </S.InformationRow>
    );
};
