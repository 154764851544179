import React, { FC, useCallback, useContext } from 'react';
import { Headline } from '../../ui/Styled/Styled';
import { Form } from 'react-final-form';
import Button from '../../ui/Button/Button';
import FormContainer from '../../ui/FormContainer/FormContainer';
import { RouterPaths } from 'helpers/router-paths';
import { AuthContext } from '../../context/Auth';
import { signupUser } from '../../api/auth/auth';
import { useNavigate } from 'react-router-dom';
import withContainer from '../../components/Container/withContainer';
import FormField from '../../ui/FormField/FormField';
import { RegisterUserFormData } from '../../api/auth/auth.types';

const Field = FormField<RegisterUserFormData>;

const RegisterUserPage: FC = () => {
    const { refresh } = useContext(AuthContext);
    const navigate = useNavigate();

    const onSubmit = useCallback(
        async (props: RegisterUserFormData) =>
            signupUser(props)(async () => {
                const authorized = await refresh();
                if (authorized) {
                    navigate(RouterPaths.Home);
                }
            }),
        [refresh, navigate]
    );

    return (
        <>
            <Headline>Регистрация пользователя</Headline>
            <Form onSubmit={onSubmit}>
                {({ handleSubmit, submitting }) => (
                    <FormContainer onSubmit={handleSubmit}>
                        <Field
                            name="email"
                            type="email"
                            required
                            label="E-mail"
                            placeholder="example@kursoved.ru"
                        />
                        <Field
                            name="surname"
                            required
                            label="Фамилия"
                            placeholder="Иванов"
                        />
                        <Field
                            name="name"
                            required
                            label="Имя"
                            placeholder="Иван"
                        />
                        <Field
                            name="lastName"
                            type="text"
                            required
                            label="Отчество"
                            placeholder="Иванов"
                        />
                        <Field
                            name="password"
                            type="password"
                            required
                            label="Пароль"
                        />
                        <Field
                            name="confirmPassword"
                            type="password"
                            required
                            label="Повторите пароль"
                        />
                        <Button type="submit" disabled={submitting}>
                            Создать
                        </Button>
                    </FormContainer>
                )}
            </Form>
        </>
    );
};

export default withContainer(RegisterUserPage);
